import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { IconsComponent } from 'src/app/uikit/icons/icons.component';
import { TranslocoModule } from "@ngneat/transloco";
import { NoSpacesDirective } from 'src/app/directives/no-spaces.directive';

export enum SearchPlaceholder {
  'searchUsers' = 'Search_users',
  'searchTag' = 'Search_tag'
}

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [CommonModule, IconsComponent, FormsModule, ReactiveFormsModule, TranslocoModule, NoSpacesDirective],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
})
export class SearchComponent implements OnInit, OnDestroy {
  public iconColor = '#929298';
  public isFocused = false;
  public searchForm: FormGroup;
  private formSub$: Subscription;
  @Input() isDropdownResultActive: boolean = false;
  @Input() placeholder: SearchPlaceholder = SearchPlaceholder.searchUsers;
  @Output() onFocus: EventEmitter<boolean> = new EventEmitter();
  @Output() searchRequest: EventEmitter<string> = new EventEmitter();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    if (this.formSub$) this.formSub$.unsubscribe();
  }

  public onMouseOver(): void {
    if (!this.isFocused) {
      this.iconColor = '#6A49FA';
    }
  }

  public onMouseOut(): void {
    if (!this.isFocused) {
      this.iconColor = '#929298';
    }
  }

  public onActiveFieldsEvent(): void {
    this.isFocused = true;
    this.iconColor = '#6A49FA';
    this.initSubscription();
    this.onFocus.emit(true);
  }

  public onFocusDisabledEvent(): void {
    this.isFocused = false;
    this.iconColor = '#929298';
    if (this.formSub$) this.formSub$.unsubscribe();
    this.onFocus.emit(false);
  }

  private initForm(): void {
    this.searchForm = this.fb.group({
      searchKeyword: [''],
    });
  }

  private initSubscription(): void {
    this.formSub$ = this.searchForm.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((data) => {
        this.searchRequest.emit(data.searchKeyword);
      });
  }

  public resetForm(): void {
    this.searchForm.reset();
  }
}
