<ng-container *transloco="let t;">
  <div [formGroup]="searchForm" class="input-container">
    <div class="suffix-container">
      <span class="suffix">
        <app-icons
          [name]="'search'"
          [width]="25"
          [height]="25"
          [fillColor]="iconColor"
        ></app-icons>
      </span>
      <input
        formControlName="searchKeyword"
        [placeholder]="t(placeholder)"
        noSpaces
        type="text"
        [ngClass]="{'dropdown-list': isDropdownResultActive}"
        (focus)="onActiveFieldsEvent()"
        (blur)="onFocusDisabledEvent()"
        (mouseover)="onMouseOver()"
        (mouseout)="onMouseOut()"
      />
    </div>
  </div>
</ng-container>
